import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

const CommunitySection = () => {
  const router = useRouter();
  return (
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 col-xs-12">
      <h6 className="links-title">
        <FormattedMessage id="footer.community.title" />
      </h6>
      <ul className="footer-links">
        <li>
          <Link href={`/${router.locale}/community-resources`} prefetch={false}>
            <a>
              <FormattedMessage id="footer.community.community-giving" />
            </a>
          </Link>
        </li>
        <li>
          <Link href={`/${router.locale}/a-world-without-ms`} prefetch={false}>
            <a>
              <FormattedMessage id="footer.community.ms-canada" />
            </a>
          </Link>
        </li>
        <li>
          <Link href={`/${router.locale}/blog`} prefetch={false}>
            <a>
              <FormattedMessage id="footer.community.blog" />
            </a>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default CommunitySection;
